<template>
	<div class="page mygroup_page">
		<div class="w1240" v-if="typeindex==0">
			<div class="block30"></div>
			<div class="mygroup_typebox">
				<div class="line"></div>
				<div class="text fontsize32">我的社团</div>
			</div>
			<div class="dataListbox">
				<div class="itemobj" :class="getRowClass(index)" v-for="(item,index) in dataList" :key="index" @click="handledetail(item)">
					<div class="groupname clamp fontsize16">{{item.title}}</div>
					<div class="groupcon clamp fontsize20">{{item.discription}}</div>
					<div class="grouptime fontsize14">{{$util.replaceTspli(item.createDate)}}</div>
				</div>
			</div>
			<div class="dataListbox" v-if="dataList.length<=0">
				<div class="nolist">你暂未加入学习社团，请联系教师邀请加入！</div>
			</div>
		</div>
		<div class="w1200" v-if="typeindex==1">
			<div class="back_typebox" @click="handletype(0)">
				<img class="icon" src="../assets/static/leftgo.png"/>
				<div class="text fontsize16">返回社团</div>
			</div>
			<div class="group_detailbox">
				<div class="leftbox">
					<div class="box1">
						<div class="typebox">
							<div class="line"></div>
							<div class="text fontsize20">{{dataobj.title}}</div>
						</div>
						<div class="textbox">
							<div class="text1 fontsize14">社团描述</div>
							<div class="text2 fontsize16">{{dataobj.discription}}</div>
						</div>
						<div class="textbox">
							<div class="text1 fontsize14">社团分类</div>
							<div class="text2 fontsize16">
								<el-tag
								  :key="tag"
								  v-for="tag in infoTags">
								  {{tagFormat(tag)}}
								</el-tag>
							</div>
						</div>
						<div class="textbox">
							<div class="text1 fontsize14">社团组建时间</div>
							<div class="text2 fontsize16">{{$util.replaceTspli(dataobj.createDate)}}</div>
						</div>
					</div>
					<div class="block20"></div>
					<div class="box1">
						<div class="typebox">
							<div class="line"></div>
							<div class="text fontsize20">社团成员</div>
						</div>
						<div class="textbox1">
							<div class="text1 fontsize14">名字</div>
							<div class="text2 fontsize14">加入时间</div>
						</div>
						<div class="textbox2" v-for="(item,index) in groupmenber" :key="index">
							<div class="text1 fontsize14">{{item.hlUser.nikename}}</div>
							<div class="text2 fontsize14">{{$util.replaceTspli(item.createDate)}}</div>
						</div>
					</div>
				</div>
				<div class="rightbox">
					<div class="typebox">
						<div class="line"></div>
						<div class="text fontsize20">作业/竞赛</div>
					</div>
					<div class="itemobj fontsize14">
						<div class="text1">标题</div>
						<div class="text2">状态</div>
						<div class="text3">开始时间</div>
						<div class="text4">结束时间</div>
						<div class="text5"></div>
					</div>
					<div class="itemobj1 fontsize14" v-for="(item,index) in zuoyeList" :key="index">
						<div class="text1">{{item.title}}</div>
						<div class="text2">
							<span class="c1" v-if="item.typeTag=='ing'">进行中</span>
							<span class="c2" v-if="item.typeTag=='end'">已结束</span>
							<span class="c3" v-if="item.typeTag=='before'">未开始</span>
						</div>
						<div class="text3">{{item.examStartTime}}</div>
						<div class="text4">{{item.examEndTime}}</div>
						<div class="text5" @click="handleworkDetail(item.id)">查看详情</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			typeindex:0,//0是小 1是详情
			dataList:[],//社团
			typeList:[],//社团类型
			dataobj:{},//社团对象
			infoTags:[],//社团类型
			groupmenber:[],//社团成员
			zuoyeList:[],//作业
		};
	},
	mounted: function() {
		
	},
	activated() {
		if(this.typeindex==0&&this.dataList.length<=0){
			//获取我的社团类型
			this.getmyGrouptype()
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole']),
	},
	methods: {
		//获取我的社团
		getmyGroup(){
			var _this = this
			//需要登录
			if(this.isLogin){
				var params = {
					currentPage:1,
					pageSize:8888,
				}
				params["userUuid"] = this.userInfo.uuid
				this.$http.post('app/hlexam/hlUserGroup/myGroup', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.dataList = res.data.records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		//获取我的社团类型
		getmyGrouptype(){
			var _this = this
			//需要登录
			if(this.isLogin){
				var params = {
					currentPage:1,
					pageSize:8888,
				}
				params["userUuid"] = this.userInfo.uuid
				this.$http.post('app/hlexam/hlUserGroupType/index', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.typeList = res.data.records
						//获取我的社团
						_this.getmyGroup()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		//获取我的社团作业
		handlezuoyeList(){
			var _this = this
			//需要登录
			var params = {
				currentPage:1,
				pageSize:8888,
				testType:1,
			}
			//params["userUuid"] = this.userInfo.uuid
			params["userGroup"] = this.dataobj.id
			this.$http.post('app/hlexam/hlQuestExam/index', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					//作业状态
					res.data.records.forEach((item, index) => {
						// console.log(row)
						var examStartTime = _this.$util.getDateTime2(item.examStartTime)
						var examEndTime = _this.$util.getDateTime2(item.examEndTime)
						try{
							item["typeTag"] = _this.$util.compareTimes(examStartTime,examEndTime)
						}catch(e){
							item["typeTag"] = ""
						}
					});
					_this.zuoyeList = res.data.records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取我的社团成员
		hlUserGroupMember(){
			var _this = this
			//需要登录
			var params = {
				currentPage:1,
				pageSize:8888,
			}
			params["groupId"] = this.dataobj.id
			this.$http.post('/app/hlexam/hlUserGroupMember/index2', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.groupmenber = res.data.records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//颜色
		getRowClass(index) {
		    // 根据需要判断第1第2第3第4列的倍数
		    const multipleClasses = {
		      '1': 'first-column',
		      '2': 'second-column',
		      '3': 'third-column',
		      '4': 'fourth-column'
		    };
		    let classNames = '';
		    Object.keys(multipleClasses).forEach(multiple => {
				if (index % multiple === 0) {
					classNames += ` ${multipleClasses[multiple]}`;
				}
		    });
		    return classNames.trim();
		},
		//标签
		tagFormat(tag){
			const obj = this.typeList.find((ifitem) => { //这里的options就是上面遍历的数据源
				return ifitem.id == tag; //筛选出匹配数据
			});
			if(obj){
				return obj.title
			}else{
				return tag
			}
		},
		//跳转
		handledetail(item){
			// this.$router.push({
			// 	path: "/class",
			// 	query:{
			// 		uid:uid
			// 	}
			// });
			this.infoTags = []
			try{
				this.infoTags = JSON.parse(item.infoTags)
			}catch(e){
				//TODO handle the exception
			}
			this.dataobj = item
			this.handletype(1)
			//获取我的社团作业
			this.handlezuoyeList()
			//获取我的社团成员
			this.hlUserGroupMember()
		},
		//作业赛事详情
		handleworkDetail(uid){
			this.$router.push({
				path: "/mygroupwork",
				query:{
					uid:uid
				}
			});
		},
		//类型
		handletype(type){
			this.typeindex = type
		},
		
		
	}
};
</script>
<style lang="scss" scoped></style>
